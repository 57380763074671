export default [
  {
    header: 'DATA MASTER [DM] 🔹',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'TBBM'
      // },
      // {
      //   resource: '',
      //   action: 'Hulu Migas'
      // },
      // {
      //   resource: 'special',
      //   action: 'manage'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  },
  {
    title: 'Kapal TBBM',
    icon: 'AnchorIcon',
    tag: 'KA',
    tagVariant: 'primary',
    route: 'data-master-river-boat-tbbm',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'TBBM'
      // },
      // {
      //   resource: 'special',
      //   action: 'manage'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  },
  {
    title: 'Kapal HM',
    icon: 'AnchorIcon',
    tag: 'KH',
    tagVariant: 'primary',
    route: 'data-master-river-boat-hulu_migas',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'Hulu Migas'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  },
  {
    title: 'Speed Boat',
    icon: 'AnchorIcon',
    tag: 'SP',
    tagVariant: 'primary',
    route: 'data-master-speed-boat',
    acl: [
      {
        resource: 'all',
        action: 'manage'
      },
      // {
      //   resource: '',
      //   action: 'Hulu Migas'
      // },
      {
        resource: '',
        action: 'SAL'
      }
    ]
  }
]
